@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

html {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(20, 20, 20);
  position: relative;
  min-height: 100%;
}

body {
  margin: 0px 0px 50vh 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-title {
  font-weight: 300;
  padding: 0 0 10vh 0;
}
.page-wrapper {
  max-width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 10vh 0 10vh 0;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background-color: rgb(20, 20, 20);
  box-shadow: 0px 1px 15px lightgray;
}
nav a {
  text-decoration: none;
}
.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}
.logo {
  height: 60px;
  padding: 10px 0px 0px 0px;
}
.brand-name {
  margin: 0px;
  padding: 0px 0px 10px 0px;
  letter-spacing: 3px;
  font-weight: 300;
  font-size: small;
  color: white;
  text-align: center;
}
.brand-description {
  margin: 50px 0px 50px 0px;
  color: white;
  font-size: small;
}

.nav-links {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-around;
  list-style: none;
  letter-spacing: 2px;
  font-size: small;
}
.nav-links li a, .nav-links li p {
  margin: 0px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(150, 150, 150);
}
.nav-icon {
  padding-right: 5px;
  color: white;
}
.nav-menu {
  color: rgb(150, 150, 150);
  display: none;
  margin: 5px;
}
.nav-dropdown {
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
}
.nav-dropdown:hover p{
  color: white;
}
.nav-dropdown-content {
  padding: 10px 0px 0px 0px;
  display: none;
  position: absolute;
  background-color: rgb(20, 20, 20);
  text-align: center;
}
.nav-dropdown-content a p {
  margin: 10px 0px 0px 0px;
  padding: 0px 10px 0px 10px;
  font-size: x-small;
  height: 40px;
  width: 100%;
}
.nav-dropdown-content a p:hover {
  color: white;
  background-color: rgb(10, 10, 10);
}
.nav-dropdown:hover .nav-dropdown-content {
  display: flex;
  flex-direction: column;
}

.banner-container {
  margin: 50px 0px 50px 0px;
  padding: 50px 0px 50px 0px;
  height: 60vh;
  background-image: url(assets/img/banner.png);
  background-position: 80% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 1px 15px lightgray;
}
.banner-card {
  padding: 0px 50px 0px 50px;
  margin: 10px 0px 10px 0px;
  background-color: rgb(20, 20, 20);
  color: rgb(50, 150, 50);
  box-shadow: 0px 1px 10px -5px rgb(87, 87, 87);
  max-width: 400px;
  text-justify: auto;
}
.card-title {
  font-size: xx-large;
  text-align: center;
}
.illustration-box{
  margin: 0px;
  padding: 0px;
  z-index: 0;
}
.illustration {
  pointer-events: none;
  margin: 0px 0px 0px -70px;
  padding: 0px;
  min-width: 300px;
  max-width: 600px;
  height: auto;
}
.unpinned-intro {
  display: none;
}
.products-container {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  box-shadow: 0px 1px 15px lightgray;
}
.products-fixed{
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  background-color: rgb(20, 20, 20);
  color: rgb(50, 150, 50);
}
.products-fixed-content {
  width: 60%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: large;
}
.products-item {
  max-width: 250px;
  max-height: 250px;
  margin: 0px auto 0px auto;
}
.products-scroll {
  width: 100%;
}
.products-scroll div {
  margin: auto;
  height: 110vh;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: large;
}
.products-scroll h1 {
  font-size: x-large;
}
.eng-translation {
  font-weight: lighter;
  font-style: italic;
}

.container {
  margin: 50px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customers-card {
  width: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0px 1px 10px -5px rgb(87, 87, 87);
  text-justify: auto;
}
.customers-item {
  justify-content: center;
  padding: 40px 20px 40px 20px;
  height: 50px;
}

.demos-container {
  background-color: rgb(20, 20, 20);
  color: rgb(50, 150, 50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.demos-container h1 {
  width: 100%;
  margin: 40px 0px 0px 0px;
  text-align: center;
  font-weight: 400;
  font-size: 64px;
}
.demos-container h2 {
  width: 100%;
  text-align: center;
  font-weight: 400;
  color:rgb(20, 20, 20);
}
.demos-card {
  width: 100%;
  margin: 50px;
  padding: 20px;
  height: 70vh;
  display: flex;
  justify-content: center;
  background-color: rgb(245, 245, 245);
  box-shadow: 0px 1px 10px -5px rgb(87, 87, 87);
  text-justify: auto;
}
.demos-card > div {
  max-width: 700px;
  padding: 40px;
  background-color: rgb(245, 245, 245);
  box-shadow: 0px 1px 10px -5px rgb(87, 87, 87);
}
.demos-item {
  display: block;
  text-decoration: none;
  font-size: large;
  width: auto;
  text-align: center;
  background-color:rgb(20, 20, 20);
  color:rgb(55, 176, 55);
  font-weight: bold;
}
.demos-jump-button {
  display: block;
  text-decoration: none;
  font-size: large;
  width: auto;
  text-align: center;
  background-color: rgb(55, 176, 55);
  color: rgb(20, 20, 20);
  font-weight: bold;
}
.demo-title {
  color: rgb(20, 20, 20);
  max-width: 300px;
}
.demo-description {
  max-width: 300px;
  color:rgb(20, 20, 20);
  font-size: small;
}

.footer {
  height: 50vh;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgb(20, 20, 20);
  color:rgb(245, 245, 245);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer-icon {
  padding-right: 5px;
  color: white;
}
.social-media  {
  list-style: none;
}
.social-media  li a {
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(150, 150, 150);
}

.about-description {
  text-align: justify;
  text-justify: inter-word;
}
.li-profile {
  text-decoration: none;
  cursor: pointer;
  color: green;
}
.li-profile:visited {
  color: green;
}

.values-title {
  font-weight: 300;
  font-size: large;
}
.values {
  padding: 20px;
}

@media screen and (max-width: 850px) {
  .illustration {
    display: none;
  }
  .page-wrapper {
    max-width: 80%;
    padding: 5vh 0 5vh 0;
  }
}

@media screen and (max-width: 768px) {
  .nav-menu {
    display: block;
    cursor: pointer;
  }
  .logo {
    height: 30px;
  }
  .brand-name {
    font-size: medium;
  }
  .nav-links {
    background-color: rgb(20, 20, 20);
    height: 90vh;
    width: 100%;
    position: absolute;
    right: 0;
    top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    z-index: 50;
  }
  .banner-container {
    margin: 0px 0px 0px 0px;
    background-position: 80% 40%;
    box-shadow: 0px 0px 0px lightgray;
  }
  .products-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .pinned-intro {
    display: none;
  }
  .unpinned-intro {
    display: contents;
  }
  .products-fixed-content {
    width: 80%;
    height: 50vh;
  }
  .products-scroll div {
    margin: auto;
    width: 80%;
  }
}

.nav-links-active { 
  transform: translateX(0%);
}
